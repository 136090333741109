import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { HeadingContainer, Text } from '../components/common/typography'
import GatsbyImage from 'gatsby-image'
import SEO from '../components/seo'
import { BLACK_COLOR_RGB, PRIMARY_COLOR } from '../components/common/color'
import bp from '../components/common/breakpoints'
import AppLayout from '../components/layouts/appLayout'


const CareersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
  width: 90%;
  margin: 3rem auto 0;
  padding-bottom: 3rem;

  ${bp[1]} {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    max-width: 660px;
  }

  ${bp[2]} {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    max-width: 860px;
  }

  ${bp[3]} {
    max-width: 1080px;
  }
`

const CareerCard = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-radius: 20px;
  box-shadow: 10px 10px 20px ${BLACK_COLOR_RGB(0.1)};
  padding: 2rem;
  height: 280px;
  box-sizing: border-box;
`

const CareerTitle = styled.h4`
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1rem;
`

const CareerSummary = styled(Text)`
  color: ${BLACK_COLOR_RGB(0.4)};
  font-weight: 400;
  overflow: hidden;
  margin-bottom: 1rem;
`

const CareerDetailsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
`

const CareerDate = styled.span`
  color: ${BLACK_COLOR_RGB(0.8)};
`
const CareerMoreInfoLink = styled(Link)`
  color: ${PRIMARY_COLOR};
  justify-self: end;
`

const CareersPage = ({ data }) => {
  const { background, allCareers } = data
  return (
    <AppLayout>
      <SEO title="Careers" canonical="careers" />
      <HeadingContainer title="Looking for talent" />
      <GatsbyImage fluid={background.fluid} />
      <CareersContainer>
        {allCareers.edges.map(({ node: career }) => (
          <CareerCard key={career.id}>
            <CareerTitle>{career.frontmatter.title}</CareerTitle>
            <CareerSummary>{career.frontmatter.summary}</CareerSummary>
            <CareerDetailsContainer>
              <CareerDate>{career.frontmatter.date}</CareerDate>
              <CareerMoreInfoLink to={`/careers/${career.parent.name}`}>
                Keep Reading
              </CareerMoreInfoLink>
            </CareerDetailsContainer>
          </CareerCard>
        ))}
      </CareersContainer>
    </AppLayout>
  )
}

export const query = graphql`
  query {
    background: imageSharp(
      fluid: { originalName: { eq: "careers-background.png" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 220) {
        ...GatsbyImageSharpFluid
      }
    }
    allCareers: allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "careers" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            summary
          }
          parent {
            ... on File {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default CareersPage
